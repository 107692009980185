import axios, { AxiosError, AxiosResponse } from "axios";
import cookies from "./cookies";

export const context = () => {
  const access_token = `${cookies.get(cookies.options.access_token)}`;
  const merchant_id = `${cookies.get(cookies.options.merchant_id)}`;
  const org_id = `${cookies.get(cookies.options.org_id)}`;
  const partner_id = `${cookies.get(cookies.options.partner_id)}`;

  return {
    ...(access_token != "undefined" && { access_token }),
    "Content-Type": "application/json",
    ...(merchant_id != "undefined" && { merchant_id }),
    ...(org_id != "undefined" && { org_id }),
    ...(partner_id != "undefined" && { "x-partner-id": partner_id }),
  };
};
export const userRequestContext = context;

export class FetchError extends Error {
  public response: AxiosResponse<any, any>;
  public needsLogin: boolean;

  constructor(
    message: string,
    options?: ErrorOptions & {
      response?: AxiosResponse<any, any>;
      needsLogin: boolean;
    },
  ) {
    super(message, options);
    this.response = options.response;
    this.needsLogin = options.needsLogin;
  }
}

const client = axios.create();
client.interceptors.request.use(
  function (config) {
    const requestContext = context();
    Object.keys(requestContext).forEach((key) => {
      if (typeof config.headers[key] === "undefined") {
        config.headers[key] = requestContext[key];
      }
    });
    return config;
  },
  function (error) {
    // Add refresh token and retry logic
    return Promise.reject(error);
  },
);

type FetchRequestOptions = {
  headers: Record<string, string>;
  method?: string;
  body?: string;
};

export const fetchRequest = async (
  path: string,
  init: FetchRequestOptions = {
    headers: context(),
    method: "GET",
  },
) => {
  if (path[0] === "/") path = `${process.env.NEXT_PUBLIC_BASE_URL}` + path;
  try {
    const { data } = await client.request({
      url: path,
      headers: {
        ...(init?.headers || {}),
      },
      method: init.method,
      data: init.body,
    });
    return data;
  } catch (error) {
    if (
      error?.response?.status === 401 &&
      error?.response?.data?.message === "Invalid session"
    ) {
      window.open("/logout", "_self");
      return [];
    }
    if (error instanceof AxiosError) {
      throw new FetchError(error?.response?.data?.message || error.message, {
        cause: error,
        response: error?.response,
        needsLogin: false,
      });
    } else {
      throw new FetchError(`Error occurred while making the request`, {
        cause: error,
        response: error?.response,
        needsLogin: false,
      });
    }
  }
};

export const mutationRequest =
  (method: string) =>
  (path, { arg = undefined, headers = {} }) => {
    if (path[0] === "/") path = `${process.env.NEXT_PUBLIC_BASE_URL}` + path;
    return fetchRequest(path, {
      // @ts-ignore
      method: method.toUpperCase(),
      ...(arg && { body: JSON.stringify(arg) }),
      headers: {
        ...context(),
        ...headers,
      },
    });
  };

export const formRequest = (path, { arg }) => {
  if (path[0] === "/") path = `${process.env.NEXT_PUBLIC_BASE_URL}` + path;

  const access_token = `${cookies.get(cookies.options.access_token)}`;
  const merchant_id = `${cookies.get(cookies.options.merchant_id)}`;

  return fetch(path, {
    // @ts-ignore
    method: "POST",
    body: arg,
    headers: {
      ...(access_token != "undefined" && { access_token }),
      ...(merchant_id != "undefined" && { merchant_id }),
      "Content-Type":
        "multipart/form-data; boundary=----WebKitFormBoundarycAgKJMU1mFxxmxLD",
    },
  });
};

export const putRequest = mutationRequest("PUT");

export const postRequest = mutationRequest("POST");

export const deleteRequest = mutationRequest("DELETE");

type PollRequestOptions = {
  shouldPollFn: (data: any) => boolean;
  shouldPollOnErrorFn: (error: Error | FetchError) => boolean;
  delay: number;
};

export const pollRequest = (path, options: PollRequestOptions) => {
  const { shouldPollFn, shouldPollOnErrorFn, delay } = options;

  return new Promise<void>((res, rej) => {
    setTimeout(() => {
      res();
    }, delay || 0);
  }).then(() => {
    return fetchRequest(path)
      .then((data) => {
        if (shouldPollFn(data)) {
          return pollRequest(path, options);
        } else {
          return data;
        }
      })
      .catch((error) => {
        if (shouldPollOnErrorFn(error)) {
          return pollRequest(path, options);
        } else {
          throw error;
        }
      });
  });
};
